<template>
<div >
	

			<v-card>
				<v-toolbar flat color="white">
                    <v-toolbar-title>Buscar</v-toolbar-title>
                    <v-divider
                        class="mx-4"
                        inset
                        vertical
                    >
                    </v-divider>
                    <v-spacer></v-spacer>
                    
                     <v-menu 
                     v-model="menu"
                      :close-on-content-click="false"
                     >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="rangoFechas"
                                    label="Fechas"
                                    persistent-hint
                                     single-line
                                    readonly
                                    append-icon="mdi-calendar-multiple"
                                    v-bind="attrs"
                                    v-on="on"
                                    :clearable="true"
                                    @change=cargarLista(true)
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                range
                                v-model="searchFechas"
                                no-title
                                @change="menu = false"
								locale="es-es"
                            >
                            
                            
                            </v-date-picker>
                        </v-menu>
                    
                    <v-text-field
                        v-model="searchCodigo"
                        append-icon="mdi-magnify"
                        label="Codigo"
                        single-line
                        
                        class='mt-3'
                        @click:append=cargarLista(true)
                        @keydown.enter=cargarLista(true)
                    ></v-text-field>
                    <v-text-field
                        v-model="searchProducto"
                        append-icon="mdi-magnify"
                        label="Producto"
                        single-line
                        
                        class='mt-3'
                        @click:append=cargarLista(true)
                        @keydown.enter=cargarLista(true)
                    ></v-text-field>
                    <v-select :items="listaBodegas"
                    	label="Bodega"
                    	dense
                    	item-text="facility_name"
          				item-value="facility_id"
                    	v-model="searchBodega"
                    	append-icon="mdi-magnify"
                    	class='mt-3'
                        @click:append=cargarLista(true)
                        @change=cargarLista(true)
                    	solo ></v-select>
                    

    </v-toolbar>
	<v-toolbar flat color="white">
		<v-btn  color="primary" small  class="ma-2 white--text" 
		@click="exportar()"
		>
			<v-icon left >mdi-microsoft-excel</v-icon>
		Exportar
		</v-btn>
	</v-toolbar>
	
	  <v-row>
         <v-col md="12" sm="12">
         <v-data-table
			    :headers="headers"
			    :loading="loadingTable"
			    :items="lista"
			    :items-per-page="10010"
			    hide-default-footer
			    class="elevation-1"
			  >
			  </v-data-table>
			  </v-col>
         </v-row>
         </v-card>
</div>
</template>


<script>
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'

export default {
	name: "ArqueoDiarioComponent",
	
	data: ()=> ({
    	 headers: [
    		 
    		  
             { text: 'Bodega', value: 'bodega' },
             { text: 'Codigo', value: 'product_id' },                                       
             { text: 'Producto', value: 'internal_name'},             
             { text: 'Cantidad en stock', value: 'cantidad_total'},
             { text: 'Disponible ', value: 'total_disponible'},
             { text: 'Inicial', value: 'cantidad_inicial' , align:'right'},
             { text: 'Egreso por venta', value: 'cantidad_venta' , align:'right'},
             { text: 'Otros egresos', value: 'ajustes_salida', align:'start'},
             { text: 'Ingreso por compra', value: 'cantidad_compra' , align:'center'},
             { text: 'Otros ingresos', value: 'ajustes_ingreso' , align:'center'},
             { text: 'Cotizaciones Pendientes', value: 'cotizaciones_pend' , align:'center'},
           ],
           lista :[],
           listaBodegas: [],
           searchFechas: [new Date().toISOString().substr(0,10),new Date().toISOString().substr(0,10)],
           options: {},
           dialog: false,
           notifications: false,
           sound: true,
           widgets: true,
           menu: false,
          
            
            
             currentPage: 1,
             pageCount: 1,
             itemsPerPage: 20,

             searchCodigo: "",
             searchProducto: "",
             searchBodega: "",
            
    }),
    computed: {
    	...mapState('master',['loadingTable','user','tenantId']),
    	
    	...mapGetters('access', ['btnAbrirCaja']),
    	rangoFechas:  {        		
    		get: function() {
            	return this.searchFechas.join(' ~ ')
    		}
    		,set:function(){
    			this.cargarLista(true)	
    			  
    		}
         },
    },
    methods: {
    	...mapMutations('master',['setUrl','setOverlay','setMenu','setTitleToolbar','setLoadingTable']),
	   	
	    ...mapActions('master',['requestApi']),
	     
	    cargarLista(filtrar) {
            
    		if(filtrar == true) {
    			this.currentPage = 1
    		}
            this.setLoadingTable(true)
            this.setUrl('inventario-por-producto')
            this.requestApi({
                method : 'POST',
                data : {                	
                	"desde" : this.searchFechas[0],
                	"hasta" : this.searchFechas[1],
                	"bodega_id": this.searchBodega,
                	"producto": this.searchProducto,
                	"product_id": this.searchCodigo,
                	"exportar" : "0"
			    }
            }).then(res=>{
            	//console.log(res.data.lista);			        			    		
            	this.lista = res.data.lista;
            	//this.pageCount = res.data.page_count;
            	this.setLoadingTable(false)
            }).then(()=>{
            	   
            })
        	 

        },
        
        exportar() {
        	
        	this.setUrl('inventario-por-producto')
            this.requestApi({
                method : 'POST',
                data : {
                	
                	"desde" : this.searchFechas[0],
                	"hasta" : this.searchFechas[1],
                	"bodega_id": this.searchBodega,
                	"producto": this.searchProducto,
                	"product_id": this.searchCodigo,
                	"exportar" : "1"
			    }
            }).then(res=>{
            	//console.log(res.data);			        			    		
            	
            	var a = document.createElement("a");
            	a.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'+ res.data.datos;
            	a.download = res.data.archivo;
            	a.click();
            	
            }).then(()=>{
	              
            })
            
        	

        },
        cargarListaBodegas() {
        	this.setUrl('bodega')
            this.requestApi({
                method : 'GET',
                data : {
                	"activas" : "1"			        
			    }
            }).then(res=>{
            				        			    		
            	this.listaBodegas = res.data._embedded.bodega;
				this.searchBodega = this.listaBodegas[0].facility_id
            }).then(()=>{
	              
            })
        	
        }
	},
	       

    mounted() {
		var temp = new Date()
		//this.searchFechas[0] = temp.getFullYear().toString() + '-' + temp.getMonth().toString() + '-' +temp.getDate().toString()        
        //this.searchFechas[1] = temp.getFullYear().toString() + '-' + temp.getMonth().toString() + '-'  +temp.getDate().toString()
        
         this.searchFechas = [new Date().toISOString().substr(0,10),new Date().toISOString().substr(0,10)]
    	 this.cargarListaBodegas()
    	 this.setTitleToolbar('ARQUEO DIARIO')
         this.cargarLista(false);
    },
    
    watch: {
    	
    	searchNumber : {
    		handler() {
    			this.cargarLista(true)
    		}
    	},
    	searchEstado : {
    		 handler () {
                 this.cargarLista(true)
             },
    	}
    }

    
  }

</script>